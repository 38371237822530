import React from 'react';
import {
  BrowserRouter, Routes, Route, Link,
} from 'react-router-dom';
import {
  Navbar, Container, Row, Col,
} from 'react-bootstrap';
import { Toaster } from 'react-hot-toast';
import { useAuth } from 'react-oidc-context';
import axios from 'axios';
import Dashboard from './Components/Dashboard/dashboard-component';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import poweredLogo from './powered-by-stem-logo.svg';

function App() {
  const auth = useAuth();

  if (!auth.isAuthenticated && !auth.isLoading && auth.activeNavigator !== 'signinRedirect') {
    auth.signinRedirect();
  }

  if (auth.isAuthenticated) {
    axios.interceptors.request.use(async (config) => {
      const updatedConfig = config;
      updatedConfig.headers.Authorization = `Bearer ${auth.user.id_token}`;

      return updatedConfig;
    });

    return (
      <BrowserRouter>
        <Toaster position="top-right" />
        <div className="app">
          <header className="App-header">
            <Navbar bg="dark" variant="dark">
              <Container>
                <Navbar.Brand>
                  <Link to="/" className="nav-link">
                    Your Impact Dashboard
                  </Link>
                </Navbar.Brand>
              </Container>
            </Navbar>
          </header>
          <Container>
            <Row>
              <Col md={12}>
                <div className="wrapper">
                  <Routes>
                    <Route exact path="/" element={<Dashboard />} />
                  </Routes>
                </div>
              </Col>
            </Row>
          </Container>
          <footer className="app-footer">
            <Container>
              <Row>
                <Col md={6}>
                  <img src={poweredLogo} alt="Powerred by STEM Learning" />
                </Col>
                <Col md={6} className="footer-tagline">
                  Feedback so we can develop too
                </Col>
              </Row>
            </Container>
          </footer>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
